import { Radio, Label, Dialog, DialogTrigger, DialogContent } from '@troon/ui';
import { Show, For, createSignal } from 'solid-js';
import { createAsync, useParams } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { AccessProducts } from '../../../../../../components/access-products';
import { getAccessProducts } from '../../../../../../providers/card';
import type { CourseTeeTimeRate } from '../../../../../../graphql';

type Props = {
	rate: CourseTeeTimeRate;
	isSelected: boolean;
};

export function AccessCardUpsellRate(props: Props) {
	const params = useParams<{ facilityId: string; teeTimeId: string }>();
	const [cardUpsellOpen, setCardUpsellOpen] = createSignal(false);
	const accessProducts = createAsync(() => getAccessProducts({}));

	return (
		<>
			<div class="relative flex justify-between rounded bg-gradient-to-r from-neutral-950 to-brand-700 p-4 text-white md:p-6">
				<Radio value={props.rate.id} checked={props.isSelected} disabled>
					<Label class="flex flex-col gap-1 ps-2">
						<span class="font-semibold">{props.rate.name}</span>
						<span class="text-sm">{props.rate.price.displayValue} per player</span>
					</Label>
				</Radio>
				<div
					aria-hidden
					class="absolute inset-0 z-10"
					onClick={() => {
						setCardUpsellOpen(true);
					}}
				/>
				<Dialog key="access-products" open={cardUpsellOpen()} onOpenChange={setCardUpsellOpen}>
					<DialogTrigger appearance="primary" class="relative z-10 shrink grow-0">
						Buy now & save
					</DialogTrigger>
					<DialogContent header="Choose your Troon Access" headerLevel="h3" autoWidth>
						<AccessProducts redirect={`/course/${params.facilityId}/reserve-tee-time/${params.teeTimeId}`} />
					</DialogContent>
				</Dialog>
			</div>
			<Show
				when={accessProducts()
					?.products.flatMap((product) => product.valueProps)
					.filter((value, index, self) => self.indexOf(value) === index)}
			>
				{(valueProps) => (
					<ul class="grid grid-cols-2 gap-x-4 gap-y-2">
						<For each={valueProps()}>
							{(prop) => (
								<li class="col-span-2 flex items-baseline gap-2 md:col-span-1">
									<Icon name="check" class="relative top-1 size-6 shrink-0 text-brand" />
									{prop}
								</li>
							)}
						</For>
					</ul>
				)}
			</Show>
		</>
	);
}
